window.bindDatepicker = (wrap, date, time, datetime, outlet) => {
  var datepicker = $(date).pickadate({
    container: outlet,
    onSet: (item) => { if ('select' in item) setTimeout(timepicker.open, 10) },
    onOpen: () => { $(wrap).addClass('wrap-expanded'); },
    onClose: () => { $(wrap).removeClass('wrap-expanded'); }
  }).pickadate('picker');

  if (datepicker !== undefined) {
    var timepicker = $(time).pickatime({
      container: outlet,
      onOpen: () => { $(wrap).addClass('wrap-expanded'); },
      onSet: (item) => {
        if ('select' in item) setTimeout( () => { onSetTime($datetime, datepicker, timepicker), 10 });
        $(wrap).removeClass('wrap-expanded');
      },
      onClose: () => { $(wrap).removeClass('wrap-expanded'); }
    }).pickatime('picker');

    var $datetime = $(datetime).on('focus', datepicker.open).on('click', (event) => { event.stopPropagation(); datepicker.open() });
  }
}

window.onSetTime = ($datetime, datepicker, timepicker) => {
  $datetime.off('focus').val(datepicker.get() + ' at ' + timepicker.get()).focus().on('focus', datepicker.open);
}
