let ajaxHeaders = { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };
$(document).on('turbolinks:load', function () {
  $(".download-all-credit_notes").click(function(){
    let downloadAllLink = $(this);
    if(downloadAllLink.attr('href') != '#') {
      return true;
    }

    let creditNoteData = downloadAllLink.data();
    let requestData = {
      url: '/partners/shops/' + creditNoteData['shopId']  + '/credit_notes/download_all?year=' + creditNoteData['year'],
      type: 'get',
      headers: ajaxHeaders,
      dataType: 'Json',
      data: ''
    };
    $.ajax(requestData).done(function(data) {
      if (data.success === true) {
	downloadAllLink.attr('href', data['zip_url']);
	window.location.href = data['zip_url'];
      }
    });
    return false;
  });
});

/* Gets called when request starts */

$(document).ajaxStart(function(){
  if($('.white-spinner').length) {
    $('.white-spinner').removeClass('hidden');
  }
});

/* Gets called when request complete */
$(document).ajaxComplete(function(event,request,set){
  if($('.white-spinner').length) {
    $('.white-spinner').addClass('hidden');
  }
});
