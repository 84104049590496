let ajaxHeaders = { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };
$(document).on('turbolinks:load', function() {

  $.each($('[data-behavior~="pickadate"]'), (index, pickdate_element) => {
    let $element = $(pickdate_element);
    let data = $element.data();
    let options = {
      format: data.format,
      hiddenName: true,
    };
    $element.pickadate(options);
    if ($element.val()) {
      let picker = $element.pickadate('picker');
      picker.set('select', new Date($element.val()));
    }
  });

 bindDatepicker('#wrap', '#date', '#time', '#datetime', '#outlet');

  let datePicker = $('.schedule-date');
  let timePicker = $('.schedule-time');

  if (datePicker.length) {
    datePicker.pickadate({
      format: datePicker.data().format,
      min: new Date(),
      container: '#outlet'
    }).pickadate('picker');
  }

  if (timePicker.length) {
    timePicker.pickatime({
      format: timePicker.data().format,
      container: '#outlet'
    }).pickatime('picker');
  }

  $('form.schedule').find('button[type="submit"]').on('click', () => {
    if (!datePicker.val() || !timePicker.val()) {
      alert(I18n.t('datetime.error.set_datetime'));
      return false;
    }
    let datetime = datePicker.val() + '-' + timePicker.val();
    let requestData = {
      url: $('form.schedule').attr('action'),
        type: 'post',
      headers: ajaxHeaders,
      dataType: 'json',
      data: { scheduled_at: datetime }
    };
    $.ajax(requestData).done((data) => {
      if (data.success ==  true) {
        alert(I18n.t('products.schedule_modal.success'));
        location.reload();

      } else {
        alert(I18n.t('products.schedule_modal.error'));
      }
    });
    return false;
  });
});
