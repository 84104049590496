$(document).on('turbolinks:load', ()=> {
  $('[data-behavior~=select2-products]').select2({
    placeholder: I18n.t('filter_options.all'),
    allowClear: true,
    theme: 'default select2-dropdown--filter',
    minimumInputLength: 3,
    ajax: {
      url: '/admins/products/for_select',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      processResults: function (data) {
        return {
          results: data
        };
      },
    },
  });
});
