document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.overview--did-you-know--header--filter')) {
    const element = document.getElementById("overview--did-you-know--filter")
    const background = document.querySelector(".overview--did-you-know--background")

    const showElement = () => {
      element.style.display = "flex"
      background.style.display = "block"
      document.querySelector('.overview--did-you-know--header--filter').setAttribute("aria-expanded", "true")
    }

    const hideElement = () => {
      element.style.display = "none"
      background.style.display = "none"
      document.querySelector('.overview--did-you-know--header--filter').setAttribute("aria-expanded", "false")
    }

    document.onkeydown = function(evt) {
      evt = evt || window.event;
      if (evt.key == 'Escape') {
        hideElement()
      }
    };

    document.querySelector('.overview--did-you-know--background').addEventListener('click', (e) => {
      e.preventDefault()
      hideElement()
    })

    document.querySelector('.overview--did-you-know--header--filter').addEventListener('click', (e) => {
      e.preventDefault()
      if (element.style.display !== "flex") {
        showElement()
      } else {
        hideElement()
      }
    })
  }
})