$(document).on('turbolinks:load', function () {
  $('form.new_partner').submit(function() {
      if ($(this).find("input[id*='-shop-status-field'][value='true']").length == 0) {
          $('#active-shop-modal').modal('show');
          return false;
      }
    return true;
  });

  let togglePaymentMethodFields = ()=> {
    $('.form-group.partner_cardholder, ' +
      '.form-group.partner_iban, ' +
      '.form-group.partner_bic, ' +
      '.form-group.partner_paypal_email').toggleClass('hidden')
  }

  $('#partner_payment_method').on('change', ()=> togglePaymentMethodFields())

  if ($('#partner_tax_reduced').length) {
    $('#account-setting-save').on('click', () => {
      let tax_reduced = $('#partner_tax_reduced');
	if (!tax_reduced.attr('disabled') && tax_reduced.is(':checked')) {
        $('#confirm-product-tax-reduce').modal('show');
        return false;
      }
      return true;
    });

    $('#submit-product-tax-reduce').on('click', () => $('.edit_partner').submit());
  }

  $('[data-behavior~="open-links-in-new-tab"] a').on('click', function(e) {
    e.target.hasAttribute('target') ? true : e.target.setAttribute('target', '_blank');
  });
});
