$(document).on('turbolinks:load', _ => {
  var countrySubentityInputToggler = _ => {
      if (document.querySelector('#partner_taxable_country_code').value == 'DE' && document.querySelector('#partner_tax_number').value) {
        document.querySelector('.partner_issued_tax_country_subentity_code').classList.remove('hidden')
      } else {
        document.querySelector('.partner_issued_tax_country_subentity_code').classList.add('hidden')
        document.querySelector('#partner_issued_tax_country_subentity_code').value = ''
      }
    }
    , birthdateInputToggler = _ => {
      if (document.querySelector('#partner_tax_number').value) {
        document.querySelector('.form-group.partner_birthdate').classList.remove('hidden')
      } else {
        document.querySelector('.form-group.partner_birthdate').classList.add('hidden')
        document.querySelector('#partner_birthdate_1i').value = ''
        document.querySelector('#partner_birthdate_2i').value = ''
        document.querySelector('#partner_birthdate_3i').value = ''
        updateBirthdateInput()
      }
    }
    , updateBirthdateInput = _ => {
      var year = document.querySelector('#partner_birthdate_1i').value
        , month = document.querySelector('#partner_birthdate_2i').value
        , day = document.querySelector('#partner_birthdate_3i').value
        , birthdate = ''

      if (year && month && day) birthdate = [year, month, day].join('-')

      document.querySelector('#partner_birthdate').value = birthdate
    }

  $('#partner_birthdate_1i, #partner_birthdate_2i, #partner_birthdate_3i').on('change', updateBirthdateInput)

  if ($('.partner_issued_tax_country_subentity_code').length) countrySubentityInputToggler()
  $('#partner_taxable_country_code, #partner_tax_number').on('change keyup', countrySubentityInputToggler)

  if (document.querySelector('#partner_tax_number')) birthdateInputToggler()
  $('#partner_tax_number').on('change keyup', birthdateInputToggler)
})
