createMultiSelectUnselectButton = () => {
  let unselectButton = $('.select2-selection__choice__remove');
  for (let index = 0; index < unselectButton.length; index++) {
    unselectButton[index].innerText = '';
  }
  unselectButton.addClass('select2-remove-right');
};

removeHighlight = (element, className) => element.removeClass(className)

$(document).on("turbolinks:before-cache", ()=> $('.select2-hidden-accessible').select2('destroy'));

$(document).on('turbolinks:load', ()=> {
  $.each($('[data-behavior~="select2"]'), (index, select2_element) => {
    let $element = $(select2_element)
    if($element.attr('multiple')) {
      $element.on('select2:select select2:unselect', ()=> createMultiSelectUnselectButton())
    }

    let data = $element.data()
    let options = {
      placeholder: data.placeholder,
      width: data.width || '100%',
      allowClear: data.allowClear,
      minimumResultsForSearch: data.minSearch || 2
    }
    if(data.behavior.match(/filter/)) {
      options['containerCssClass'] = 'select2-selection--filter'
      options['dropdownCssClass'] = 'select2-dropdown--filter'
    }

    $element.select2(options)
  })
  createMultiSelectUnselectButton()
});
