import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "button" ]

  connect() {
    this.savedForm = {}

    if (!this.data.get('initialShow')) {
      this.emptyForm()
    }

    this.updateVisibility(!!this.data.get('initialShow'))
  }

  show() {
    this.restoreForm()

    this.updateVisibility(true)
  }

  hide() {
    this.emptyForm()

    this.updateVisibility(false)
  }

  inputs() {
    return Array.from(this.contentTarget.getElementsByTagName('input'))
  }

  emptyForm() {
    this.inputs().forEach(input => {
      this.savedForm[input.name] = input.value

      input.value = null
    })
  }

  restoreForm() {
    this.inputs().forEach(input => {
      input.value = this.savedForm[input.name]
    })
  }

  updateVisibility(show){
    if (show) {
      this.contentTarget.classList.add('visible')
    } else {
      this.contentTarget.classList.remove('visible')
    }

    this.buttonTargets.forEach(item => {
      if (!!item.dataset.show === show) {
        item.classList.add('button--primary')
      } else {
        item.classList.remove('button--primary')
      }
    })
  }
}
