function disableAddFieldButton(property) {
  let buttonSelector = $(`#add_${property}_field`)
  let numberOfFields = document.querySelectorAll(`#product_${property}`).length
  let maxNumberOfFields = buttonSelector.data('max-elements')
  if (numberOfFields >= maxNumberOfFields) buttonSelector.addClass('invisible');
}

$(document).on('turbolinks:load', ()=> {
  $('.button-add-new').click( function() {
    let property = $(this).data('property');
    let fieldName = `product[${property}][]`;
    let fieldId = `product_${property}`;
    let propertyFieldsContainer = $(`.${property}-fields-wrapper`);

    propertyFieldsContainer.append(`<input multiple='multiple' class='field appended-field' type='text' value='' name='${fieldName}' id='${fieldId}'>`);

    disableAddFieldButton(property);
  });
});
