import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'count', 'comment', 'icon']

  save() {
    let url = this.data.get('save-information-url')
    let name = this.data.get('name')
    let data = { 'name': name, 'count': this.countTarget.value, 'comment': this.commentTarget.value }
    this.patch(url, data)
  }

  patch(url, data) {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    }

    fetch(url, options)
      .then(response => {
        if (response.ok) {
          this.iconTarget.classList.remove('hidden')
          this.buttonTarget.classList.add('button-dark-green')
          this.countTarget.classList.add('saved')
          this.commentTarget.classList.add('saved')
        }
      })
  }
}
