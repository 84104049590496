$(document).on('turbolinks:load', () => {

  // Fallback for development
  var copyToClipboard = (text) => {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).trigger('select');
    document.execCommand("copy");
    $temp.remove();
  }

  $('body').on('click', '[data-behavior~="copy-to-clipboard"]', (event) => {
    event.preventDefault();
    if (navigator.clipboard === undefined) {
      copyToClipboard(event.target.dataset.copy);
      return;
    }
    navigator.clipboard.writeText(event.target.dataset.copy);
  })

});
