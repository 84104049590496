import consumer from "./channels/consumer"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'file']

  initialize() {
    let container = this.containerTarget
    let file = this.fileTarget
    let product_id = this.data.get('product')

    consumer.subscriptions.create({ channel: "DxfProductChannel", product_id }, {
      connected() { },

      disconnected() { },

      received(data) {
        container.classList.remove('blocked')
        if (data.action == 'render') {
          container.innerHTML = data.html
        }
        if (data.action == 'dxfFile'){
          file.classList.toggle('done')
        }
      }
    });
  }

  refresh() {
    this.send_data({action: 'show'})
  }

  generateLayers() {
    this.send_data({action: 'split'})
  }

  regenerateQrCode() {
    this.send_data({action: 'qrcode'})
  }

  setApproved() {
    this.send_data({action: 'approved', approved: true})
  }

  setUnapproved() {
    this.send_data({action: 'approved', approved: false})
  }

  send_data(body) {
    this.containerTarget.classList.add('blocked')
    let url = this.data.get('layer-url')
    fetch(
      url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }
}
