$(document).on('turbolinks:load', () => {

  I18n.locale = $('html').attr('lang');

  let activateShopButton = $('.partner--new .activate-shop, .partner--edit .activate-shop');

  const STRINGS = {
    active: I18n.t('expressions.active'),
    activateShop: I18n.t('partners.new.activate_shop'),
    inactive: I18n.t('expressions.inactive'),
    deactivateShop: I18n.t('partners.new.deactivate_shop'),
    createShop: I18n.t('partners.new.create_shop'),
    true: 'true',
    false: 'false'
  };

  activateShopButton.click( function() {
    $('.active-shops-label').removeClass('text-pink');
    $(this).toggleClass('text-pink');
    let currentShop = $(this).data('shop');
    $(`#${currentShop}-shop-complete-icon, #${currentShop}-shop-incomplete-icon`).toggleClass('d-none');

    if ($(this).hasClass('text-pink')) {
      toggleShopStatus(this, 'deactivateShop', 'active', 'true');
    } else {
      if ($(this).hasClass('new-shop')) {
        toggleShopStatus(this, 'createShop', 'inactive', 'false');
      } else {
        toggleShopStatus(this, 'activateShop', 'inactive', 'false');
      }
    }
  });

  function toggleShopStatus(element, title, innerHTML, value) {
    let currentShop = $(element).data('shop');
    let currentShopStatus = $(`#${currentShop}-shop-status`)[0];
    let currentShopStatusField = $(`#${currentShop}-shop-status-field`)[0];

    $(element)[0].innerHTML = STRINGS[title];
    currentShopStatus.innerHTML = STRINGS[innerHTML];
    currentShopStatusField.value = STRINGS[value];
  }
});
