$(document).on('turbolinks:load', ()=> {
  $('#images').sortable({
    items:'.template-image',
    cursor: 'move',
    opacity: 0.5,
    dragging: true,
    distance: 10,
    refreshPositions: true,
    handle: '.move-image',
    update: () => {
      let updated_order = [];
      $('.template-image').each((index, element) => updated_order.push($(element).attr('id')));

      $.ajax({
        type: 'POST',
        url: $('form').attr('action') + '/sort_images',
        data: { order: updated_order },
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
      });
    }
  });

  $('#sort-products-table-body').sortable({
    items:'.product-thumbnail',
    cursor: 'move',
    opacity: 0.5,
    dragging: true,
    distance: 10,
    refreshPositions: true,
    update: () => {
      let updated_order = [];
      $('.product-thumbnail').each((index, element) => updated_order.push($(element).attr('id')));
      $.ajax({
        type: 'POST',
        url: $('#sort-products-table-body').data('url'),
        data: { products: updated_order },
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
      });
    }
  });

});
