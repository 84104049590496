$(document).on('turbolinks:load', ()=> {
  $.each(document.querySelectorAll('[data-behavior~=export-modal]'), (_, modal) => {
    var $modal = $(modal)

    $modal.find('[data-behavior~=export-submit]').on('click', () => {
      // This is not tested yet, as shop coupons does not implement different export types
      // It does not cause an error when uncommented.
      // Need to be tested if an other export modal is change to this generic implementation
      // $modal.find('input[name=export_type]').val($modal.find('.modal-tab.tab-active').data('exportType'))

      $.each($modal.find('input[type=hidden]'), (_, filter) => {
        var source_selector =
          filter.dataset.checkbox ? `[name=${filter.dataset.source}]:checked` : `[name=${filter.dataset.source}]`
        $(filter).val($($modal.data('source')).find(source_selector).val());
      });
    });
  });
});
