$(document).on('turbolinks:load', ()=> {
  if (document.querySelector('#sold-items-export-modal')) {
    let modalTabs = $('#sold-items-export-modal .modal-tab')

    modalTabs.on('click', ()=> {
      modalTabs.toggleClass('tab-active')
      $('#sold-items-export-modal .tab-content').toggleClass('d-none')
    })

    $('input[type="checkbox"][data-behavior="check-all"]').on('change', (e)=> {
      let select_all = e.target
      $(select_all).closest('form').
        find('input[type="checkbox"][data-behavior!="check-all"]').
        prop('checked', select_all.checked)
    })

    $('input[type="checkbox"][data-behavior!="check-all"]').on('change', (e)=> {
      let $form = $(e.target).closest('form')
      $form.
        find('input[type="checkbox"][data-behavior="check-all"]').
        prop('checked', $form.find('input[type="checkbox"][data-behavior!="check-all"]:not(:checked)').length === 0)
    })

    $('#sold-item-export-button').on('click', ()=> {
      $('#export_type').val($('#sold-items-export-modal').find('.modal-tab.tab-active').data('exportType'))

      $('#export_search').val($('#search').val())
      $('#export_marketplace').val($('#marketplace').val())
      $('#export_date_min').val($('#date_min').val())
      $('#export_date_max').val($('#date_max').val())
      $('#export_filter').val($('#filter').val())
      $('#export_shop_id').val($('#sold_item_shop_filter').val())
      $('#export_product').val($('#product').val())
    })
  }
});
