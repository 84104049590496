Dropzone.autoDiscover = false;

$(document).on('mouseover','.thumbnail', (e) => $(e.currentTarget).next().removeClass('d-none'));
$(document).on('mouseleave', '.actions', () => $('.dropzone-image-box .actions').addClass('d-none'));

$(document).on('turbolinks:load', () => {
  $.each($('[data-behavior="dropzone"]'), (index, element) => { new MppDropzone(element).init(); });
});

class MppDropzone {
  constructor(element) {
    this.element = element;
    this.fileType = element.dataset.url;
    this.totalProgress = document.querySelector(element.dataset.totalProgress);
    this.defaultPreview = this.setDefaultPreview();
    this.preview_template = this.initializeDropzoneTemplate(element.dataset.template_selector);
    this.ajaxHeaders = { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };

    if (element.dataset.baseUrl) {
      this.baseUrl = element.dataset.baseUrl;
    } else {
      this.baseUrl = $('form').attr('action');
    }
  }

  init() {
    this.initializeDropzone();
    this.dropZoneEvents();
  }

  initializeDropzoneTemplate(nodeSelector) {
    let previewNode = this.element.querySelector(nodeSelector);

    if (previewNode !== null) {
      let template =  previewNode.parentNode.innerHTML;

      previewNode.id = "";
      previewNode.parentNode.removeChild(previewNode);

      return template;
    }
  }

  setDefaultPreview() {
    if(this.element.dataset.circleButton) {
        return $('.dropzone-circle-select-btn').html();
    }

    return $('.dropzone-simple-select-btn').html();
  }

  initializeDropzone() {
    const dataset = this.element.dataset;

    this.dropZone = new Dropzone(this.element, {
      dictDefaultMessage: this.defaultPreview,
      dictRemoveFile: I18n.t('buttons.remove_file'),
      dictCancelUpload: I18n.t('buttons.cancel_upload'),
      dictRemoveFileConfirmation: this.element.dataset.deleteConfirmation,
      previewTemplate: this.preview_template,
      addRemoveLinks: dataset.removeLink || false,
      maxFiles: dataset.maxFiles || 1,
      maxFilesize: dataset.maxFilesize || 5,
      timeout: 0,
      dictFileTooBig: I18n.t(
        'dropzone.file_size_too_big', { filesize: '{{filesize}}', maxFilesize: '{{maxFilesize}}' }
      ),
      parallelUploads: 1,
      hiddenInputContainer: '#' + this.element.id,
      uploadMultiple: dataset.multipleUpload || false,
      acceptedFiles: dataset.fileType,
      url: this.baseUrl + '/upload_' + this.fileType,
      headers: this.ajaxHeaders,
      autoDiscover: false,
      transformFile: dataset.square_transform == 'true' ? this.squareTransform : this.noTransform,
      paramName: 'file',
      init: () => {
        let fullUrl= this.baseUrl + '/find_' + this.fileType;

        $.get(fullUrl, {file_type: dataset.file}, (data) => {
          this.setFiles(this.element.id, this.dropZone, data);
        });
      }
    });
  }

  squareTransform(file, done) {
    const featuredImageDropZone = this;
    const overlay = new CropperOverlay(URL.createObjectURL(file));

    overlay.addConfirmListener('click', () => {
      const canvas = overlay.getCroppedCanvas({
        maxWidth:  2000,
        maxHeight: 2000
      });

      const blob = Dropzone.dataURItoBlob(canvas.toDataURL('image/jpeg'));

      featuredImageDropZone.createThumbnail(
        blob,
        featuredImageDropZone.options.thumbnailWidth,
        featuredImageDropZone.options.thumbnailHeight,
        featuredImageDropZone.options.thumbnailMethod,
        false,

        (dataURL) => {
          featuredImageDropZone.emit('thumbnail', file, dataURL);
          done(blob);
        }
      );

      overlay.clear();
    });

    overlay.addCancelListener('click', () => {
      overlay.clear();
      featuredImageDropZone.removeFile(file);
    });
  }

  noTransform(file, done) {
    done(file);
  }

  dropZoneEvents() {
    this.dropZone.on('removedfile', (file) => {
      this.removeFile(this.dropZone, file);
    });

    this.dropZone.on('addedfile', (file) => {
      if (this.element.dataset.maxFiles && this.dropZone.files.length > this.element.dataset.maxFiles) {
        this.dropZone.removeFile(file);
      } else if (this.element.dataset.url !== 'images') {
        this.onAddedFile(this.dropZone, file);
      }
    });

    this.dropZone.on('sending', (file, xhr, formData) => {
      if (this.totalProgress) { this.totalProgress.style.display = 'block' }
      file.previewTemplate.querySelector('[role=progressbar]').style.opacity = '1';
      document.querySelector('form input[name="commit"]').setAttribute('disabled', '');
      formData.append('file_type', file.type);

      if (this.element.dataset.square_transform == 'true') {
        formData.append('raw_filename', file.name);
        formData.append('raw_file', Dropzone.dataURItoBlob(file.dataURL));
      }
    });

    this.dropZone.on('complete', (file, progress) => {
      file.previewTemplate.querySelector('[role=progressbar]').style.opacity = '0';
    });

    this.dropZone.on('queuecomplete', (file, progress) => {
      if (this.totalProgress) { this.totalProgress.style.display = 'none' }
      document.querySelector('form input[name="commit"]').removeAttribute('disabled');
    });

    this.dropZone.on('totaluploadprogress', () => {
      if (this.totalProgress && (this.dropZone.getQueuedFiles().length || this.dropZone.getUploadingFiles().length)) {
        // https://github.com/enyo/dropzone/issues/690#issuecomment-264804851
        let progress = 0
        let allFilesBytes = 0
        let allSentBytes = 0
        let upload;

        for (let index = 0; index < this.dropZone.files.length; index++) {
          if (!this.dropZone.files[index].size) continue;

          allFilesBytes = allFilesBytes + this.dropZone.files[index].size;
          if (upload = this.dropZone.files[index].upload) {allSentBytes = allSentBytes + upload.bytesSent}
          progress = (allSentBytes / allFilesBytes) * 100
        }

        let percentage = `${Math.floor(progress)}%`
        let progress_bar = this.totalProgress.querySelector('.progress-bar')
        progress_bar.style.width = percentage
        progress_bar.innerText = percentage
      }
    });

    this.dropZone.on('success', (file, response) => {
      switch (file.type.split('/')[0]) {
        case 'image':
          file.previewElement.id = this.element.id + '_' + response['id'];
          file.image_id = response['id'];
          break;
        case 'video':
          file.video_id = response['id'];
          break;
      }

      if (typeof(response) == 'object') {
        this.setFileLink(file.previewElement, response['src']);
        this.setImageOriginalLink(file.previewElement, response['src_original']);
      }

      this.updateCropButtonImageUrl(response['src_raw_original']);
      this.showCropButton();
    });

    this.dropZone.on('error', (file, response) => { alert(response.message || response); this.dropZone.removeFile(file); });
  }

  removeFile(dropZone, file) {
    let fileType = this.fileType

    if (file.accepted) {
      this.hideCropButton()
      let requestData = {
        url: `${this.baseUrl}/remove_${fileType}`,
        type: 'post',
        headers: this.ajaxHeaders,
        dataType: 'json'
      };

      switch (fileType) {
        case 'images':
          requestData.data = { image_id: file.image_id };
          break;
        case 'videos':
          requestData.data = { video_id: file.video_id };
          break;
        default:
          requestData.data = { file_type: fileType };
          dropZone.files = [];
      }

      $.ajax(requestData);
    }
  }

  onAddedFile(dropZone, file) {
    let fileName = file.name;
    let fileExtension = fileName.split('.').pop();
    $(`#${this.element.id} .extension`).html("." + fileExtension);
    this.setFileLink(dropZone.element, file.src)
    this.setImageOriginalLink(file.previewElement, file.src_original)
  }

  pushFile(file, dropZone) {
    dropZone.files.push(file);
    dropZone.emit('addedfile', file);
    dropZone.emit('thumbnail', file, file.src);
    this.setImageOriginalLink(file.previewElement, file.src_original)
  }

  setFileLink(element, src) {
    let fileLink = $(element).find('a[data-dz-name]')
    if (fileLink.length === 1) { fileLink.attr('href', src) }
  }

  setImageOriginalLink(image_element, src_original_image) {
    $(image_element).find('.actions a[data-expand-image]').attr('href', src_original_image)
  }

  setFiles(file_type, dropZone, data) {
    switch (file_type) {
      case 'images':
        if (data.length === 0 ) { return; }

        data.forEach((item) => {
          this.pushFile(item, dropZone);
          item.previewElement.id = item.image_id + '_image';
        });

        break;
      case 'videos':
        if (data.length === 0 ) { return; }

        data.forEach((item) => {
          this.pushFile(item, dropZone);
        });

        break;
      default:
        if (data.accepted) { this.pushFile(data, dropZone); }
    }
  }

  showCropButton() {
    if (this.element.dataset.square_transform != 'true') {
      return;
    }
    $('#featured-image-crop-btn').show();
  }

  hideCropButton() {
    if (this.element.dataset.square_transform != 'true') {
      return;
    }
    $('#featured-image-crop-btn').hide();
  }

  updateCropButtonImageUrl(url) {
    $('#featured-image-crop-btn').attr('data-image-url', url);
  }
}
