import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['price']

  updatePrice() {
    console.log("updating price");
    let url = this.data.get('save-information-url');
    let price = this.priceTarget.value;
    let data = { action: "updateprice", price: price };
    this.patch(url, data)
  }

  patch(url, data) {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    }

    fetch(url, options)
      .then(response => {
        if (response.ok) {
          this.iconTarget.classList.remove('hidden')
          this.buttonTarget.classList.add('button-dark-green')
          this.countTarget.classList.add('saved')
          this.commentTarget.classList.add('saved')
        }
      })
  }
}
