$(document).on('turbolinks:load', () => {
  $.each($('[data-behavior~="filter"]'), (i, element) => {
    $(element).on('change', () => show_button());
  });
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('date_max') || urlParams.has('date_min')) {
    show_button();
  }
  function show_button() {
    $('#clear-filters-button').css('display', 'inline-block');
  }
});
