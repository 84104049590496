import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "header", "body" ]

  toggleVisibility() {
    this.bodyTarget.classList.toggle('visible')
    this.headerTarget.classList.toggle('open')
  }
}
