$(document).on('turbolinks:load', () => {
  $('#featured-image-crop-btn').on('click', (event) => {
    squareTransform(new CropperOverlay(event.target.dataset.imageUrl));
  })
});

const squareTransform = (overlay) => {
  overlay.addConfirmListener('click', (event) => {
    event.preventDefault();
    const canvas = overlay.getCroppedCanvas({
      maxWidth:  2000,
      maxHeight: 2000
    });

    const blob = Dropzone.dataURItoBlob(canvas.toDataURL('image/jpeg'));
    const formData = new FormData();
    const updateUrl = $('#files-form').attr('action') + '/update_featured_image';
    formData.append('file', blob);

    $.ajax(updateUrl, {
      method:      'POST',
      data:        formData,
      processData: false,
      contentType: false,
      enctype:     'multipart/form-data',
      headers:     { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      success(data) {
        overlay.clear();
        $('#template-featured-image img').attr('src', data.featured_image_thumbnail);
        $('#template-featured-image a[data-expand-image]').attr('href', data.featured_image_original);
      },
      error() { console.log('Upload error'); }
    });
  });

  overlay.addCancelListener('click', () => { overlay.clear(); });
}
