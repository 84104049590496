$(document).on('turbolinks:load', ()=> {
  if (window.visualViewport.width >= 768)
    $(".collapse--desktop .active-nav").parent(".collapse--desktop").removeClass("collapsed");

  $("[data-behavior~=collapsable]").on('click', (e) => {
    e.stopPropagation();
    if (e.target.tagName == 'A') return;

    $this = $(e.delegateTarget);
    $parents = $this.parents("[data-behavior~=collapsable]");
    $parents.removeClass('collapsed');
    $this.toggleClass('collapsed');
  });
});
