$(document).on('turbolinks:load', ()=> {
  $('#sold_item_shop_filter').on('change', get_products_to_select);
  $('#sold_item_shop_filter').trigger('change');
});

function get_products_to_select() {
  let shop_id = $(this).val();
  $('#product').select2({
    placeholder: 'All',
    allowClear: true,
    theme: 'default select2-dropdown--filter sold_item_filter',
    ajax: {
      url: '/admins/products/for_shop?shop_id=' + shop_id,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  });
}
